var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { alpha } from '@mui/material/styles';
// ----------------------------------------------------------------------
export default function Switch(theme) {
    var isLight = theme.palette.mode === 'light';
    var rootStyle = function (ownerState) { return (__assign(__assign({ padding: '9px 13px 9px 12px', width: 58, height: 38 }, (ownerState.size === 'small' && {
        padding: '4px 8px 4px 7px',
        width: 40,
        height: 24,
    })), { '& .MuiSwitch-thumb': __assign({ width: 14, height: 14, boxShadow: 'none', color: "".concat(theme.palette.common.white, " !important") }, (ownerState.size === 'small' && {
            width: 10,
            height: 10,
        })), '& .MuiSwitch-track': {
            opacity: 1,
            borderRadius: 14,
            backgroundColor: alpha(theme.palette.grey[500], 0.48),
        }, '& .MuiSwitch-switchBase': __assign(__assign({ left: 3, padding: 12 }, (ownerState.size === 'small' && {
            padding: 7,
        })), { '&.Mui-checked': __assign({ transform: 'translateX(13px)', '&+.MuiSwitch-track': { opacity: 1 } }, (ownerState.size === 'small' && {
                transform: 'translateX(9px)',
            })), '&.Mui-disabled': {
                '& .MuiSwitch-thumb': { opacity: isLight ? 1 : 0.48 },
                '&+.MuiSwitch-track': { opacity: 0.48 },
            } }) })); };
    return {
        MuiSwitch: {
            styleOverrides: {
                root: function (_a) {
                    var ownerState = _a.ownerState;
                    return rootStyle(ownerState);
                },
            },
        },
    };
}
